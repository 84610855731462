<template>
  <header>
    <nav class="navbar navbar-dark bg-dark fixed-top top-menu">
      <div class="container-fluid">
        <button class="btn btn-light" @click="go_back">
          <i class="bi bi-arrow-left-short"></i>
        </button>
        <router-link class="navbar-brand" :to="{ name: 'Home' }">
          <img
            class="bi me-2"
            width="41"
            height="32"
            role="img"
            aria-label="时空记忆"
            src="../assets/logo.png"
          />时空记忆
        </router-link>

        <!-- data-bs-toggle="collapse"
          data-bs-target="#navbarsExtendMenu"
          aria-controls="navbarsExtendMenu"
          aria-expanded="false"
          aria-label="Toggle navigation" -->
        <button
          class="navbar-toggler"
          type="button"
          @click="toggleCollapseMenu"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarsExtendMenu">
          <ul class="navbar-nav me-auto mb-2">
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'Home',
                  params: { region_id: region ? region.code : null },
                }"
                class="nav-link"
                :class="{
                  active: $route.name == 'Home',
                }"
                @click="hideCollapseMenu"
              >
                <i class="bi bi-house"></i>
                首页
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'Map',
                  params: { region_id: $route.params.region_id },
                }"
                class="nav-link"
                :class="{
                  active: $route.name === 'Map',
                }"
                @click="hideCollapseMenu"
              >
                <i class="bi bi-cursor"></i>
                地图服务
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'MapMirror',
                  params: { region_id: $route.params.region_id },
                }"
                class="nav-link"
                :class="{
                  active: $route.name === 'MapMirror',
                }"
                @click="hideCollapseMenu"
              >
                <i class="bi bi-diamond-half"></i>
                时空之镜
              </router-link>
            </li>
            <!-- <li class="nav-item">
              <router-link
                :to="{
                  name: 'Page',
                  params: {
                    id: 'timeline',
                    region_id: $route.params.region_id,
                  },
                }"
                class="nav-link"
                :class="{
                  active:
                    $route.name === 'Page' && $route.params.id === 'timeline',
                }"
              >
                <i class="bi bi-magic"></i>
                时空轨迹
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'Page',
                  params: {
                    id: 'metaverse',
                    region_id: $route.params.region_id,
                  },
                }"
                class="nav-link"
                :class="{
                  active:
                    $route.name === 'Page' && $route.params.id === 'metaverse',
                }"
              >
                <i class="bi bi-lightbulb"></i>
                元宇宙探索
              </router-link>
            </li> -->
            <!-- <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                >Dropdown</a
              >
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li>
                  <a class="dropdown-item" href="#">Something else here</a>
                </li>
              </ul>
            </li> -->
          </ul>
          <!-- <form role="search">
            <input
              class="form-control"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
          </form> -->
          <form
            v-if="$route.name != 'Search'"
            role="search"
            @submit.prevent="search"
          >
            <input
              type="search"
              class="form-control"
              placeholder="搜索..."
              aria-label="搜索"
              v-model="keyword"
            />
          </form>
          <div class="text-center m-3 text-white">
            <div class="me-3">辽宁省应用基础研究计划</div>
            <div class="me-3">沈阳市科学技术计划</div>
            <div class="">沈阳市数字文旅与智能应用重点实验室</div>
            <div class="">沈阳师范大学旅游管理学院数字文旅创新应用平台</div>
          </div>
        </div>
      </div>
    </nav>
  </header>

  <!-- <header>
    <div class="px-3 py-2 text-bg-dark bg-primary top-menu">
      <div class="container">
        <div
          class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start"
        >
          <router-link
            :to="{ name: 'Home' }"
            class="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none"
          >
            <img
              class="bi me-2"
              width="82"
              height="64"
              role="img"
              aria-label="时空记忆"
              src="../assets/logo.png"
            /><b class="fs-1">时空记忆</b>
          </router-link>
          <div class="me-lg-auto mt-3 mb-3">
            <div>
              <div class="fs-5">
                {{ region_name }}历史文化旅游资源四维时空导航公共服务云平台
              </div>
              <div class="sub-title">
                <span class="me-3">辽宁省应用基础研究计划</span>
                <span class="me-3">沈阳市科学技术计划</span>
                <span class="">沈阳市数字文旅与智能应用重点实验室</span>
              </div>
            </div>
          </div>
          <div class="text-end d-flex">
            <button type="button" class="btn btn-light text-dark me-2">
              登录
            </button>
            <button type="button" class="btn btn-primary">注册</button>
          </div>
        </div>
      </div>
    </div>
    <div class="px-3 py-2 border-bottom mb-3">
      <div class="container d-flex flex-wrap justify-content-center">
        <ul class="nav col-12 col-lg-auto mb-2 mb-lg-0 me-lg-auto menu">
          <li class="menu-item">
            <router-link
              :to="{
                name: 'Home',
                params: { region_id: region ? region.code : null },
              }"
              class="nav-link"
              :class="{
                'text-secondary': $route.name === 'Home',
                'text-black': $route.name !== 'Home',
              }"
            >
              <i class="bi bi-house d-block mx-auto mb-1 menu-icon"></i>
              首页
            </router-link>
          </li>
          <li class="menu-item">
            <div class="dropdown text-end">
              <a
                href="#"
                class="nav-link dropdown-toggle text-black"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                v-if="sub_region_list"
              >
                <i class="bi bi-diagram-3 d-block mx-auto mb-1 menu-icon"></i>
                省市节点
              </a>
              <router-link
                v-else-if="region"
                :to="{
                  name: 'Home',
                  params: {
                    region_id: region.code.substring(0, region.code.length - 2),
                  },
                }"
                class="nav-link text-black"
                ><i class="bi bi-diagram-3 d-block mx-auto mb-1 menu-icon"></i>
                上级节点
              </router-link>
              <div
                v-if="sub_region_list"
                class="dropdown-menu region_container"
              >
                <ul class="text-small region_select">
                  <li v-if="region">
                    <router-link
                      :to="{
                        name: 'Home',
                        params: {
                          region_id: region.code.substring(
                            0,
                            region.code.length - 2
                          ),
                        },
                      }"
                      class="dropdown-item"
                    >
                      上级节点
                    </router-link>
                  </li>
                  <li v-for="item in sub_region_list" v-bind:key="item.code">
                    <router-link
                      :to="{ name: 'Home', params: { region_id: item.code } }"
                      class="dropdown-item"
                    >
                      <span
                        v-if="item.code == '21' || item.code == '2101'"
                        class="fs-4"
                        style="color: #8b2500"
                      >
                        {{ item.name }}</span
                      >
                      <template v-else>
                        {{ item.name }}
                      </template>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li class="menu-item">
            <a :href="db_link" class="nav-link text-black">
              <i class="bi bi-database d-block mx-auto mb-1 menu-icon"></i>
              数据服务
            </a>
          </li>
          <li class="menu-item">
            <router-link
              :to="{
                name: 'Map',
                params: { region_id: $route.params.region_id },
              }"
              class="nav-link text-black"
            >
              <i class="bi bi-cursor d-block mx-auto mb-1 menu-icon"></i>
              地图服务
            </router-link>
          </li>
          <li class="menu-item">
            <router-link
              :to="{
                name: 'MapMirror',
                params: { region_id: $route.params.region_id },
              }"
              class="nav-link text-black"
            >
              <i class="bi bi-diamond-half d-block mx-auto mb-1 menu-icon"></i>
              时空之镜
            </router-link>
          </li>
          <li class="menu-item">
            <router-link
              :to="{
                name: 'Page',
                params: { id: 'app', region_id: $route.params.region_id },
              }"
              class="nav-link"
              :class="{
                'text-secondary':
                  $route.name === 'Page' && $route.params.id === 'app',
                'text-black': !(
                  $route.name === 'Page' && $route.params.id === 'app'
                ),
              }"
            >
              <i class="bi bi-download d-block mx-auto mb-1 menu-icon"></i>
              智能应用
            </router-link>
          </li>
          <li class="menu-item">
            <router-link
              :to="{
                name: 'Page',
                params: { id: 'timeline', region_id: $route.params.region_id },
              }"
              class="nav-link"
              :class="{
                'text-secondary':
                  $route.name === 'Page' && $route.params.id === 'timeline',
                'text-black': !(
                  $route.name === 'Page' && $route.params.id === 'timeline'
                ),
              }"
            >
              <i class="bi bi-magic d-block mx-auto mb-1 menu-icon"></i>
              时空轨迹
            </router-link>
          </li>
          <li class="menu-item">
            <router-link
              :to="{
                name: 'Page',
                params: { id: 'metaverse', region_id: $route.params.region_id },
              }"
              class="nav-link"
              :class="{
                'text-secondary':
                  $route.name === 'Page' && $route.params.id === 'metaverse',
                'text-black': !(
                  $route.name === 'Page' && $route.params.id === 'metaverse'
                ),
              }"
            >
              <i class="bi bi-lightbulb d-block mx-auto mb-1 menu-icon"></i>
              元宇宙探索
            </router-link>
          </li>
        </ul>
        <form
          v-if="$route.name != 'Search'"
          class="text-end search-form"
          role="search"
          @submit.prevent="search"
        >
          <input
            type="search"
            class="form-control"
            placeholder="搜索..."
            aria-label="搜索"
            v-model="keyword"
          />
        </form>
      </div>
    </div>
  </header> -->
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted, watch } from "@vue/runtime-core";
import axios from "axios";
import { useRouter } from "vue-router";
import { get_region_id_from_url } from "@/composables/Utils.js";

import { Collapse } from "bootstrap";

export default {
  setup() {
    const keyword = ref("");
    let router = useRouter();
    const search = () => {
      router.push({
        name: "Search",
        params: {
          region_id: router.currentRoute.value.params.region_id,
        },
        query: {
          keyword: keyword.value,
        },
      });
    };

    const region = ref(null);
    const region_name = ref("");
    const sub_region_list = ref(null);
    const db_link = ref("https://db.timemarking.com");

    function load_region_info(region_id) {
      // 如没有地区信息 设置地区未空 暨全国
      if (!region_id) {
        region_id = "";
      }

      // 不显示区以下信息 只处理到地级市
      if (region_id.length > 4) {
        router.push({ name: "Home" });
        return;
      }

      // 获取区域基本信息
      if (region_id) {
        axios
          .get(`/api/region/${region_id}/`)
          .then((res) => {
            region.value = res.data;
            region_name.value = region.value.name;
          })
          .catch((err) => {
            console.log(err);
            router.push({ name: "Home" });
          });
      } else {
        region_name.value = "中国";
        region.value = null;
      }

      // 省级以上加载子区域
      if (
        region_id.length < 4 &&
        ["11", "12", "31", "50", "81", "82"].indexOf(region_id) == -1
      ) {
        axios.get("/api/region/?code=" + region_id).then(function (response) {
          sub_region_list.value = response.data;
        });
      } else {
        sub_region_list.value = null;
      }

      if (region_id == "21") {
        db_link.value = "https://ln.db.timemarking.com";
      } else if (region_id == "2101") {
        db_link.value = "https://sy.db.timemarking.com";
      } else {
        db_link.value = "https://db.timemarking.com";
      }
    }

    let region_id = get_region_id_from_url();
    load_region_info(region_id);

    watch(router.currentRoute, (to, from) => {
      if (from.name === undefined) {
        return;
      }
      if (from.params.region_id == to.params.region_id) {
        return;
      }
      if (to.name == "Home") {
        load_region_info(to.params.region_id);
      }
    });

    const go_back = () => {
      history.back();
      hideCollapseMenu();
    };

    // 隐藏顶部下拉菜单
    let collapseMenu = null;
    onMounted(() => {
      collapseMenu = new Collapse("#navbarsExtendMenu", {
        toggle: false,
      });
    });
    const hideCollapseMenu = () => {
      collapseMenu.hide();
    };
    const toggleCollapseMenu = () => {
      collapseMenu.toggle();
    };

    return {
      region,
      region_name,
      sub_region_list,
      db_link,

      keyword,
      search,

      go_back,

      collapseMenu,
      hideCollapseMenu,
      toggleCollapseMenu,
    };
  },
};
</script>

<style scoped>
header {
  height: 55px;
}

.top-menu {
  background-color: #6699cc !important;
  /* background-color: #1E90FF !important; */
  /* position: fixed;
  top: 0;
  left: 0;
  width: 100%; */
  z-index: 999999;
}

.menu-icon {
  width: 24px;
  height: 24px;
  font-size: 20px;
}

.sub-title {
  word-wrap: break-word;
  display: flex;
  flex-wrap: wrap;
}

.region_container {
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 99999;
}

.region_select {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-width: 500px;
  max-height: 500px;
  list-style: none;
  line-height: 28px;
}

.menu-title {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.menu {
  width: calc(100% - 224px);
  justify-content: space-around;
}

.search-form {
  display: flex;
  align-items: center;
}
</style>