<template>
  <div class="background"></div>
  <div class="background-content"></div>

  <PageHeader />

  <router-view />

  <PageFooter />
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import { onMounted } from "@vue/runtime-core";

export default {
  name: "App",
  head: {
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} - 时空记忆` : "时空记忆";
    },
  },
  components: { PageHeader, PageFooter },
  setup() {
    onMounted(() => {
      // document.body.style.backgroundImage = `url(${require("./assets/background.jpg")})`;
      // document.body.style.backgroundAttachment = "fixed";
      // document.body.style.backgroundSize = "cover";
    });
  },
};
</script>

<style>
a:link {
  text-decoration: none;
}
a:active {
  text-decoration: blink;
}
a:hover {
  text-decoration: underline;
}
a:visited {
  text-decoration: none;
}
</style>

<style scoped>
>>> .md {
  background: none;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url(assets/background8.png); */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right center;
  z-index: -1;
  /* filter: blur(8px); */
}

.background-content {
  position: fixed;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(255, 255, 255, 0.6); */
  /* 0.75 */
  z-index: -1;
  /* filter: blur(5px);    */
  /* 12px */
}

@media (min-width: 576px) {
  .background-content {
    max-width: 576px;
  }
}

@media (min-width: 768px) {
  .background-content {
    max-width: 768px;
  }
}

@media (min-width: 992px) {
  .background-content {
    max-width: 992px;
  }
}

@media (min-width: 1200px) {
  .background-content {
    max-width: 1200px;
  }
}

@media (min-width: 1400px) {
  .background-content {
    max-width: 1400px;
  }
}
</style>