import { createWebHistory, createRouter } from "vue-router";

const routes = [{
  path: "/:region_id?",
  name: "Home",
  component: () =>
    import('@/views/HomePage.vue'),
  meta: {
    title: `四维时空导航`
  }
},
{
  path: "/:region_id?/search",
  name: "Search",
  component: () =>
    import('@/views/SearchView.vue'),
  meta: {
    title: `搜索`
  }
},
{
  path: "/:region_id?/data/:type?/",
  name: "Data",
  component: () =>
    import('@/views/DataView.vue'),
  meta: {
    title: `数据`
  }
},
{
  path: "/:region_id?/location/:id/",
  name: "Location",
  component: () =>
    import('@/views/LocationView.vue'),
  meta: {
    title: `建筑地点`
  }
},
{
  path: "/:region_id?/building/:id/",
  name: "Building",
  component: () =>
    import('@/views/BuildingView.vue'),
  meta: {
    title: `时空片段`
  }
},
{
  path: "/:region_id?/segment/:id/",
  name: "Segment",
  component: () =>
    import('@/views/SegmentView.vue'),
  meta: {
    title: `时空片段信息`
  }
},
{
  path: "/:region_id?/person/:id/",
  name: "Person",
  component: () =>
    import('@/views/PersonView.vue'),
  meta: {
    title: `历史人物`
  }
},
{
  path: "/:region_id?/history/:id/",
  name: "History",
  component: () =>
    import('@/views/HistoryView.vue'),
  meta: {
    title: `历史事件`
  }
},
{
  path: "/:region_id?/organize/:id/",
  name: "Organize",
  component: () =>
    import('@/views/OrganizeView.vue'),
  meta: {
    title: `关联单位`
  }
},
{
  path: "/:region_id?/page/:id/",
  name: "Page",
  component: () =>
    import('@/views/PageView.vue'),
  meta: {
    title: `四维时空导航`
  }
},
{
  path: "/:region_id?/pagem/:id/",
  name: "PageM",
  component: () =>
    import('@/views/PageViewB.vue'),
  meta: {
    title: `四维时空导航`
  }
},
{
  path: "/:region_id?/map/",
  name: "Map",
  component: () =>
    import('@/views/MapView.vue'),
  meta: {
    title: `地图服务`
  }
},
{
  path: "/:region_id?/mapmirror/",
  name: "MapMirror",
  component: () =>
    import('@/views/MapMirrorView.vue'),
  meta: {
    title: `时空之镜`
  }
},

];

const router = createRouter({
  // https://next.router.vuejs.org/guide/essentials/history-mode.html
  history: createWebHistory(),
  routes,
});

export default router;