<template>
  <footer class="page-footer">
    <div class="row fixed-bottom text-center foot-menu">
      <div class="col">
        <router-link
          :to="{
            name: 'Home',
            params: { region_id: region ? region.code : null },
          }"
          class="nav-link"
          :class="{
            'text-secondary': $route.name === 'Home',
            'text-black': $route.name !== 'Home',
          }"
        >
          <i class="bi bi-house d-block mx-auto mb-1 menu-icon"></i>
          首页
        </router-link>
      </div>
      <div class="col">
        <router-link
          :to="{
            name: 'Map',
            params: { region_id: $route.params.region_id },
          }"
          class="nav-link"
          :class="{
            'text-secondary': $route.name === 'Map',
            'text-black': !($route.name === 'Map'),
          }"
        >
          <i class="bi bi-cursor d-block mx-auto mb-1 menu-icon"></i>
          地图服务
        </router-link>
      </div>
      <div class="col">
        <router-link
          :to="{
            name: 'MapMirror',
            params: { region_id: $route.params.region_id },
          }"
          class="nav-link"
          :class="{
            'text-secondary': $route.name === 'MapMirror',
            'text-black': !($route.name === 'MapMirror'),
          }"
        >
          <i class="bi bi-diamond-half d-block mx-auto mb-1 menu-icon"></i>
          时空之镜
        </router-link>
      </div>
      <!-- <div class="col">
        <router-link
          :to="{
            name: 'Page',
            params: { id: 'timeline', region_id: $route.params.region_id },
          }"
          class="nav-link"
          :class="{
            'text-secondary':
              $route.name === 'Page' && $route.params.id === 'timeline',
            'text-black': !(
              $route.name === 'Page' && $route.params.id === 'timeline'
            ),
          }"
        >
          <i class="bi bi-magic d-block mx-auto mb-1 menu-icon"></i>
          时空轨迹
        </router-link>
      </div> -->
      <!-- <div class="col">
        <router-link
          :to="{
            name: 'Page',
            params: { id: 'metaverse', region_id: $route.params.region_id },
          }"
          class="nav-link"
          :class="{
            'text-secondary':
              $route.name === 'Page' && $route.params.id === 'metaverse',
            'text-black': !(
              $route.name === 'Page' && $route.params.id === 'metaverse'
            ),
          }"
        >
          <i class="bi bi-lightbulb d-block mx-auto mb-1 menu-icon"></i>
          元宇宙探索
        </router-link>
      </div> -->
      <div class="col">
        <router-link
          :to="{
            name: 'PageM',
            params: { id: 'aboutus', region_id: $route.params.region_id },
          }"
          class="nav-link"
          :class="{
            'text-secondary': $route.name === 'PageM',
            'text-black': !($route.name === 'PageM'),
          }"
        >
          <i class="bi bi-lightbulb d-block mx-auto mb-1 menu-icon"></i>
          关于我们</router-link
        >
      </div>
    </div>
    <!-- <div class="col mb-3">
      <router-link
        :to="{ name: 'Home' }"
        class="d-flex align-items-center mb-3 link-dark text-decoration-none"
      >
        <img class="bi me-2" width="240" src="../assets/logo_text.png" />
      </router-link>

      <p class="text-muted">
        <img
          src="../assets/zhangxiang_logo.jpg"
          height="30"
          alt="藏象科技"
          class="me-1"
        />Copyright © 2022 藏象科技.
        <span class="text-nowrap">All Rights Reserved</span>
      </p>
    </div>

    <div class="col mb-3"></div>

    <div class="col mb-3">
      <h5>
        <router-link
          :to="{
            name: 'Home',
            params: { region_id: $route.params.region_id },
          }"
          style="color: black"
          >返回主页</router-link
        >
      </h5>
      <ul class="nav flex-column">
        <li class="nav-item mb-2">
          <router-link
            :to="{
              name: 'PageM',
              params: { id: 'aboutus', region_id: $route.params.region_id },
            }"
            class="nav-link p-0 text-muted"
            >关于我们</router-link
          >
        </li>
        <li class="nav-item mb-2">
          <router-link
            :to="{
              name: 'PageM',
              params: {
                id: 'termsofservice',
                region_id: $route.params.region_id,
              },
            }"
            class="nav-link p-0 text-muted"
            >服务条款</router-link
          >
        </li>
        <li class="nav-item mb-2">
          <router-link
            :to="{
              name: 'PageM',
              params: { id: 'copyright', region_id: $route.params.region_id },
            }"
            class="nav-link p-0 text-muted"
            >版权声明</router-link
          >
        </li>
        <li class="nav-item mb-2">
          <router-link
            :to="{
              name: 'PageM',
              params: { id: 'contactus', region_id: $route.params.region_id },
            }"
            class="nav-link p-0 text-muted"
            >联系我们</router-link
          >
        </li>
        <li class="nav-item mb-2">
          <router-link
            :to="{
              name: 'PageM',
              params: { id: 'changelog', region_id: $route.params.region_id },
            }"
            class="nav-link p-0 text-muted"
            >更新日志</router-link
          >
        </li>
        <li class="nav-item mb-2">
          <router-link
            :to="{
              name: 'PageM',
              params: { id: 'feedback', region_id: $route.params.region_id },
            }"
            class="nav-link p-0 text-muted"
            >意见反馈</router-link
          >
        </li>
      </ul>
    </div>

    <div class="col mb-3">
      <h5>联系方式</h5>
      <ul class="nav flex-column">
        <li class="nav-item mb-2">
          <span class="nav-link p-0 text-muted">网站联系人:</span>
        </li>
        <li class="nav-item mb-2">
          <span class="nav-link p-0 text-muted">隋先生</span>
        </li>
        <li class="nav-item mb-2">
          <span class="nav-link p-0 text-muted">联系方式: </span>
        </li>
        <li class="nav-item mb-2">
          <span class="nav-link p-0 text-muted">13840490199</span>
        </li>
        <li class="nav-item mb-2">
          <span class="nav-link p-0 text-muted">电子邮箱:</span>
        </li>
        <li class="nav-item mb-2">
          <a href="mailto:20086947@qq.com" class="nav-link p-0 text-muted"
            >20086947@qq.com</a
          >
        </li>
      </ul>
    </div> -->
  </footer>
</template>

<style scoped>
.page-footer {
  height: 60px;
}

.foot-menu {
  background-color: #f5f5f5;
  font-size: 14px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
</style>